import logo from '../images/logo-paraguai.png';
import React from 'react';
import { Container } from '../styles/components/footer';

const Footer = () => {
  return (
    <Container>
      <img src={logo} alt='' />
      <span>
        "Tive fome e deste me de comer, tive sede e deste-me de comer, estive nú
        e vestiste-me" MT. 25:35.
      </span>
      <div className='informations'>
        <p>Ministério Atos de Justiça</p>
        <p>Av. Ministro Jõao Arinos, 4136</p>
        <p>B. Tiradentes | CG-MS.</p>
        <p>(+55) 67 | 3341-6317</p>
        <p>contato@atosdeamor.org</p>
      </div>
    </Container>
  );
};

export default Footer;
