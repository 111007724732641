import styled from 'styled-components'

export const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #222;
  background-color: #eee;
  border-top: 1px solid #aaa;
  padding: 2rem 4rem;

  img {
    width: 16rem;
    border-right: 1px solid #ccc;
    padding-right: 2rem;
    margin-right: 2rem;
  }

  span {
    font-size: 1.6rem;
    width: 30rem;
  }

  .informations {
    font-size: 1.4rem;
    padding-left: 2rem;
    margin-left: 2rem;
    border-left: 1px solid #ccc;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    img {
      padding: initial;
      margin: initial;
      border: none;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid #ccc;
    }
    .informations {
      padding: initial;
      margin: initial;
      border: none;
      padding-top: 2rem;
      margin-top: 2rem;
      border-top: 1px solid #ccc;
    }
  }
`
