import React from 'react';
import { Link } from 'react-scroll';
import { Container, NavLink } from '../styles/components/header';
import logo from '../images/logo.png';

const Header = ({ showScroll }) => {
  return (
    <Container showScroll={showScroll}>
      <div className='logo'>
        <img src={logo} alt='' />
      </div>
      <nav className='navigator'>
        <ul className='menu'>
          <li>
            <Link to='home' smooth={true} offset={-100}>
              <NavLink>Home</NavLink>
            </Link>
          </li>
          <li>
            <Link to='about' smooth={true} offset={-100}>
              <NavLink>Sobre</NavLink>
            </Link>
          </li>
          <li>
            <Link to='gallery' smooth={true} offset={-100}>
              <NavLink>Galeria</NavLink>
            </Link>
          </li>
          <li>
            <Link to='donate' smooth={true} offset={-100}>
              <NavLink>Contribua</NavLink>
            </Link>
          </li>
          <li>
            <Link to='responsables' smooth={true} offset={-100}>
              <NavLink>Responsáveis</NavLink>
            </Link>
          </li>
          <li>
            <Link to='contact' smooth={true} offset={-100}>
              <NavLink>Contato</NavLink>
            </Link>
          </li>
        </ul>
      </nav>
    </Container>
  );
};

export default Header;
