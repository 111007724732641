import styled from 'styled-components';

interface HeaderProps {
  showScroll: Boolean;
}

export const Container = styled.header<HeaderProps>`
  animation: appear 2s forwards;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10rem;
  min-height: 9rem;
  border-bottom: 1px solid #f0f0f013;
  width: 100%;
  z-index: 100;
  position: ${(props) => (props.showScroll ? 'sticky' : 'relative')};
  top: ${(props) => props.showScroll && 0};
  background: ${(props) => (props.showScroll ? '#fff' : 'transparent')};
  box-shadow: ${(props) => props.showScroll && '1px 1px 8px #333'};

  img {
    width: 16rem;
    opacity: 0;
    animation: appear 2s forwards;
    filter: invert() brightness(500%);

    filter: ${(props) => props.showScroll && 'none'};
  }

  .navigator {
    display: inline;
    opacity: 0;
    animation: appear 2s forwards;

    .menu {
      display: inline;
      list-style: none;

      li {
        display: inline;
        padding-left: 4rem;

        a {
          color: ${(props) => props.showScroll && 'var(--purple)'};

          :hover {
            color: ${(props) => props.showScroll && 'var(--red)'};
          }
        }
      }
    }
  }

  .menu-button {
    background: none;
    border: none;
    color: #f1f1f1;
    font-size: 3rem;
    display: none;
  }

  .celphone-navigator {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
  }

  @media (max-width: 768px) {
    & {
      padding: 0 3rem;
      min-height: 8rem;
    }
    & img {
      width: 17rem;
    }
    .navigator .menu {
      display: none;
    }
    .menu-button {
      display: block;
    }
  }
`;
export const NavLink = styled.a`
  color: #f0f0f0;
  text-transform: uppercase;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  transition: 0.3s;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  letter-spacing: 0.1rem;

  :hover,
  &.active {
    color: var(--red);
  }
`;
