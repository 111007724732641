import styled from 'styled-components';
import lixao from '../../images/lixao.png';
import donnate from '../../images/photos/IMG_8750.jpg';
import child from '../../images/child-boy.jpg';

export const Container = styled.main`
  @keyframes upside-down {
    0% {
      bottom: 1.5rem;
    }
    100% {
      bottom: 1rem;
    }
  }

  @keyframes grow {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .scrollTop {
    position: fixed;
    bottom: 3.2rem;
    right: 5.4rem;
    align-items: center;
    font-size: 4rem;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.8;
    color: var(--red);
  }

  .scrollTop:hover {
    opacity: 1;
  }

  #whatsapp {
    position: fixed;
    z-index: 999;
    color: #fff;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 1.6rem;
    font-size: 2.5rem;
    background: #00bfa5;
    padding: 0.4rem 0.6rem;
    border-radius: 50px;
    border: 2px solid #f1f1f1;
    animation: grow 2s 1s ease alternate infinite;
    box-shadow: -2px 2px 3px #b6b6b6c5;
  }

  .banner {
    position: relative;
    width: 100%;
    height: 89vh;
    overflow: hidden;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .banner video {
    width: 100%;
    filter: brightness(70%);
    z-index: -1;
    position: fixed;
    top: 0;
  }

  .banner .center {
    position: absolute;
    left: 29rem;
    top: 6rem;
    padding: 0 5rem;
    z-index: 1;
    display: flex;
    align-items: center;
    z-index: 2;
    opacity: 0;
    animation: appear 2.4s 0.8s forwards;
  }

  .banner .center img {
    width: 32rem;
    transform: translate3d(0, 0, 0);
  }

  .banner .center .text {
    width: 60rem;
    position: relative;
    z-index: 2;
    transform: translate3d(0, 0, 0);
  }

  .banner .center .text h1 {
    font: 900 8rem/8rem 'Montserrat', sans-serif;
    color: #f1f1f1;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.473);
    align-self: flex-start;
  }

  .banner .center .text p {
    font: 300 2rem 'Lato', sans-serif;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    color: #fff;
  }

  .banner .center .text a:hover {
    background: #5644bb;
  }

  .arrow-down {
    position: absolute;
    font-size: 5rem;
    color: #fff;
    bottom: 1rem;
    animation: upside-down 0.5s ease alternate infinite;
  }

  @media (max-width: 768px) {
    .banner video {
      width: auto;
      height: 100%;
    }
    .banner .center {
      padding: 0;
      width: 100%;
      top: 4rem;
      left: 0;
      display: flex;
      flex-direction: column;
    }
    .banner .center img {
      width: 20rem;
      margin: 0;
      transform: none;
    }
    .banner .center .text {
      width: 100%;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: none;
    }
    .banner .center .text h1 {
      font-size: 4.2rem;
      line-height: 1;
      margin-top: 3rem;
      align-self: center;
      text-align: center;
    }
    .banner .center .text p {
      font-size: 1.6rem;
    }
    .arrow-down {
      font-size: 4rem;
    }
    #whatsapp {
      left: 0.8rem;
    }
  }
`;

export const Title = styled.h2`
  font: 900 4.8rem 'Montserrat', sans-serif;
  text-align: center;
  color: var(--red);
  text-shadow: 1px 1px 2px #aaa;
  z-index: 2;
  position: relative;
  animation: appear 1s forwards;

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`;

export const Button = styled.a`
  font: 600 2.2rem 'Lato', sans-serif;
  color: #fff;
  display: inline-block;
  margin-top: 2.4rem;
  border: 1px solid var(--red);
  padding: 0.8rem 2rem;
  border-radius: 10px;
  background-color: var(--blue);
  transition: 0.3s;

  :hover {
    background: #5644bb;
  }

  svg {
    margin-left: 1rem;
  }
`;

export const TitleMinimal = styled.h3`
  font: 900 3rem 'Montserrat', sans-serif;
  text-align: center;
  margin-top: -0.2rem;
  color: #fff;
  text-transform: uppercase;
  z-index: 1;
  position: relative;
  text-shadow: 1px 1px 2px var(--blue);

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const About = styled.section`
  position: relative;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: center;
  padding: 4rem 10rem;
  row-gap: 1rem;
  background: #fff;
  min-height: 40rem;
  box-shadow: 0 0 4px rgb(26, 26, 26);

  .text {
    display: grid;
    grid-template-areas:
      'a b'
      'c d';
    column-gap: 5.6rem;
    row-gap: 5.6rem;
    margin: 3.2rem 0;
  }

  .text .text_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text .text_item h3 {
    font: 600 1.8rem 'Montserrat', sans-serif;
    margin-bottom: 0.8rem;
    color: #222;
  }

  .text .text_item p {
    font: 400 1.5rem/1.5 'Lato', sans-serif;
    color: #888;
  }

  .text .text_item span {
    color: var(--blue);
    font-size: 6rem;
    font-weight: 900;
    margin: 0 2.4rem;
  }

  @media (max-width: 768px) {
    padding: 5rem 2rem;
    width: 100%;

    .text {
      grid-template-areas: none;
    }
    .text .text_item {
      padding: 0 2rem;
    }
    .text .text_item svg {
      margin: 0;
      margin-right: 3rem;
    }
    .text .text_item article {
      width: 100%;
    }
    .text .text_item h3 {
      font-size: 1.7rem;
    }
  }
`;

export const Mission = styled.section`
  width: 100%;
  position: relative;
  padding: 5rem 12rem;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .missionGrid {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    gap: 8rem;

    .gridPhoto {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        position: relative;
        max-width: 80%;
      }
    }
  }

  ::before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: brightness(40%) grayscale(100%);
    width: 100%;
    position: absolute;
    height: 100%;
    background-image: url(${lixao});
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    z-index: 0;
  }

  h2 {
    font-size: 5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-bottom: 2rem;
    text-shadow: -2px 2px 3px var(--red);
  }

  span {
    width: 70rem;
    display: block;
    font-size: 2rem;
    font-family: 'Lato', sans-serif;
    color: #d2d2d2;
  }

  h2,
  span,
  a {
    z-index: 2;
    position: relative;
    color: #fff;
  }

  a svg {
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    padding: initial;
    padding: 5rem 2rem;

    h2 {
      font-size: 3rem;
    }
    span {
      width: 100%;
      font-size: 1.6rem;
    }
    a {
      font-size: 2rem;
    }
  }
`;

export const Map = styled.section`
  padding: 5rem 10rem;
  background: #f1f1f1;
  border-bottom: 2px solid var(--blue);
  border-top: 1px solid #aaa;

  h2 {
    padding: 4.8rem 0 2.4rem;
  }

  iframe {
    width: 100%;
    max-height: 30rem;
    border: 0;
    transform: translate3d(0, 0.2rem, 0);
  }

  @media (max-width: 768px) {
    padding: 5rem 0;
  }
`;

export const Gallery = styled.section`
  background: #ddd;
  padding: 5rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .photo_grid {
    display: grid;
    margin: 3rem 0;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;
  }

  .photo_grid .image {
    position: relative;
  }

  .photo_grid .image .purple {
    width: 100%;
    height: 100%;
    background: #352783b0;
    position: absolute;
    opacity: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: 0.3s;
    font-size: 5rem;
  }

  .photo_grid .image .purple a {
    color: #fff;
  }

  .photo_grid .image:hover .purple {
    opacity: 1;
  }

  @media (max-width: 768px) {
    padding: 5rem 3rem;

    .photo_grid {
      grid-template-columns: none;
    }
  }
`;

export const Donnate = styled.section`
  padding: 5rem 10rem;
  border-top: 1px solid #ccc;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ::before {
    content: '';
    background-image: url(${donnate});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    filter: brightness(40%) blur(1px);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  p {
    text-align: center;
    width: 80%;
    font-size: 2rem;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: #fff;
    margin: 3rem auto 0;
    position: relative;
    text-shadow: 0px 2px 3px var(--purple);
  }

  h2 {
    color: #fff;
    font-size: 6rem;
    text-shadow: 1px 1px 2px var(--red);
  }

  a {
    margin-top: 2.4rem;
    font-size: 3.2rem;
    position: relative;
  }

  .accounts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    width: 100%;
    margin-top: 3.2rem;
  }

  .accounts .accounts-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    z-index: 3;
  }

  .accounts .accounts-item + .accounts-item {
    border-left: 2px solid #e92c2c;
  }

  .accounts .accounts-item p {
    margin: 0;
    width: 100%;
    text-shadow: none;
    font-weight: 400;
    margin-bottom: 0.8rem;
    font-size: 2.2rem;
  }

  .accounts .accounts-item h4 {
    font: 900 2.8rem 'Montserrat', sans-serif;
    color: #fff;
    margin-bottom: 0.8rem;
  }

  .accounts .accounts-item img {
    max-width: 22rem;
    filter: invert() brightness(500%);
    margin-bottom: 1.6rem;
  }

  @media (max-width: 768px) {
    padding: 7rem 3rem;

    p {
      width: 100%;
    }

    .accounts {
      flex-direction: column;
    }
    .accounts-item {
      margin-bottom: 3rem;
    }
    .accounts .accounts-item h4 {
      font-size: 2.4rem;
    }
    .accounts .accounts-item + .accounts-item {
      border-left: none;
      border-top: 1px solid #e92c2c;
      padding-top: 3rem;
    }
    .accounts .accounts-item p {
      width: 100%;
    }
  }
`;

export const Responsables = styled.section`
  padding: 5rem;
  padding: 5rem 10rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .responsables-grid {
    display: grid;
    grid-template-columns: 50fr 50fr;
    padding-top: 5rem;
    column-gap: 3rem;
  }

  .responsables-grid img {
    border: 3px solid var(--blue);
    padding: 0.5rem;
    width: 85%;
    align-self: center;
    justify-self: center;
    box-shadow: -3px 3px 6px rgb(194, 194, 194);
  }

  .responsables-grid article h3 {
    font: 900 3.4rem 'Lato', sans-serif;
    color: var(--purple);
    margin-bottom: 2rem;
  }

  .responsables-grid article p {
    margin-bottom: 2rem;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    color: #444;
  }

  @media (max-width: 768px) {
    padding: 5rem 3rem;

    .responsables-grid {
      grid-template-columns: none;
      row-gap: 4rem;
      padding: initial;
      margin-top: 5rem;
    }
    .responsables-grid article h3 {
      text-align: center;
      font-size: 3rem;
    }
    .responsables-grid article p {
      text-align: justify;
    }
    .responsables-grid img {
      width: 100%;
    }
  }
`;

export const Contact = styled.section`
  position: relative;
  border-top: 1px solid #aaa;
  padding: 5rem 10rem;

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    background: no-repeat top/cover url(${child});
    background-attachment: fixed;
    filter: brightness(50%);
  }

  .contact-grid {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    column-gap: 4.8rem;
    padding-top: 4rem;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: 2;
  }

  .contact-grid .contact-item {
    background: #f1f1f1;
    box-shadow: -2px 3px 4px rgba(163, 163, 163, 0.377);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem 0;
    border-radius: 5px;
  }

  .contact-grid .contact-item svg {
    color: var(--blue);
    font-size: 3.2rem;
  }

  .contact-grid .contact-item h3 {
    font: 900 2.6rem 'Montserrat', sans-serif;
    color: var(--red);
    margin: 0.8rem;
  }

  .contact-grid .contact-item p {
    font: 400 1.8rem 'Lato', sans-serif;
    color: #181616;
  }

  .subtitle {
    font-size: 2rem;
    color: #f1f1f1;
    text-align: center;
    margin-bottom: 1.6rem;
    position: relative;
    z-index: 2;
  }

  @media (max-width: 768px) {
    padding: 7rem 3rem;

    .contact-grid {
      grid-template-columns: none;
      row-gap: 4rem;
    }
  }
`;
