import React from 'react';
import Helmet from 'react-helmet';
import { useEffect, useState } from 'react';
import {
  FaArrowCircleUp,
  FaArrowDown,
  FaArrowRight,
  FaEnvelope,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaSearchPlus,
} from 'react-icons/fa';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {
  About,
  Button,
  Contact,
  Container,
  Donnate,
  Gallery,
  Map,
  Mission,
  Responsables,
  Title,
  TitleMinimal,
} from '../styles/pages/index';
import { StaticImage } from 'gatsby-plugin-image';
import videoSrcURL from '../videos/banner.webm';
import banco from '../images/bb.png';
import pix from '../images/pix.png';
import nationsHelp from '../images/Nations-Help.png';
import favicon from '../images/favicon.ico';

const Home = () => {
  const [showScroll, setShowScroll] = useState(false);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 300) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 300) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
  });

  return (
    <Container>
      <Helmet>
        <title>Missão Paraguai</title>
        <link rel='icon' href={favicon} />
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='robots' content='index, follow' />
        <meta
          name='description'
          content='Conheça mais sobre a nossa missão no Paraguai, e nos ajude a levar o evangelho aos necessitados.'
        />
        <meta property='og:url' content='https://missaoparaguai.com.br' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='Missão Paraguai' />
        <meta property='”og:site_name”' content='Missão Paraguai' />
        <meta
          property='og:description'
          content='Conheça mais sobre a nossa missão no Paraguai, e nos ajude a levar o evangelho aos necessitados.'
        />
        <meta
          property='og:image'
          content='https://missaoparaguai.com.br/favicon.ico'
        />
        <link rel='canonical' href='https://missaoparaguai.com.br/' />
      </Helmet>

      <FaArrowCircleUp
        className='scrollTop'
        onClick={scrollTop}
        style={{ height: 40, display: showScroll ? 'flex' : 'none' }}
      />

      <Header showScroll={showScroll} />

      <div className='banner' id='home'>
        <video autoPlay loop>
          <source src={videoSrcURL} />
        </video>
        <div className='center'>
          <StaticImage src='../images/ribompy.png' alt='' />
          <div className='text'>
            <h1>
              Paraguai <br /> Uma questão de amor & justiça
            </h1>
            <p>
              Conheça nossa frente de trabalho <br />
              na fronteira e veja como cooperar conosco.
            </p>
          </div>
        </div>
        <i className='arrow-down'>
          <FaArrowDown />
        </i>
      </div>

      <About>
        <div id='about'>
          <Title>Sobre nós</Title>
          <div className='text'>
            <div className='text_item'>
              <span>01</span>
              <article>
                <h3>Porque o Paraguai?</h3>
                <p>
                  Marcado por reflexos da sangrenta "Guerra do Paraguai", a
                  derrota fez do Paraguai um dos países mais atrasados da
                  América do Sul, os anos de conflito geraram uma nação órfã,
                  sem paternidade, e consequentemente o Paraguai tornou-se uma
                  nação sem destino.
                </p>
              </article>
            </div>
            <div className='text_item'>
              <span>02</span>
              <article>
                <h3>O começo</h3>
                <p>
                  Com inicio ainda timido, foi alugada uma casa para o trabalho
                  de assistir crianças em situação de vulnerabilidade que vivem
                  de forma precária na região. Em pouco tempo o local ficou
                  cheio.
                </p>
              </article>
            </div>
            <div className='text_item'>
              <span>03</span>
              <article>
                <h3>Levamos o amor de Deus</h3>
                <p>
                  Então, chegou a vez das famílias, que foram atraídas pelo que
                  estava sendo feito na vida de seus filhos, sendo assim
                  iniciaram-se os trabalhos que em pouco tempo dariam frutos
                  para eternidade.
                </p>
              </article>
            </div>
            <div className='text_item'>
              <span>04</span>
              <article>
                <h3>Como estamos hoje</h3>
                <p>
                  Atualmente, a Missão Paraguai atende de 150 a 200 crianças
                  semanalmente na região do bairro San Ramon e presta auxílio
                  mensal às famílias do aterro sanitário do bairro San Lourenço,
                  região que segue em processo de transformação, essas famílias
                  estão sendo alcançadas e estão conhe cendo o nome do Senhor
                  Jesus.
                </p>
              </article>
            </div>
          </div>
        </div>
      </About>

      <Mission>
        <div className='missionGrid'>
          <div>
            <h2>Essa é a nossa missão</h2>
            <span>
              Em parceria com Nations Help, a Missão Paraguai deve, nos próximos
              anos, estender sua atuação em outros projetos de geração de renda,
              para assim, trazer dignidade aos filhos da terra e também, para
              recuperar algo que foi perdido na nação paraguaia, que é a
              paternidade.
            </span>
            <Button href='#'>
              Leia Mais <FaArrowRight />
            </Button>
          </div>

          <div className='gridPhoto'>
            <img src={nationsHelp} alt='' />
          </div>
        </div>
      </Mission>

      <Map>
        <Title>Onde estamos</Title>
        {/* eslint-disable-next-line*/}
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58954.19777722396!2d-55.76387106652235!3d-22.555253340346557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94626ee9ba8f5937%3A0x3ef74413abc1ee6b!2sPedro%20Juan%20Caballero%2C%20Paraguai!5e0!3m2!1spt-BR!2sbr!4v1612908859986!5m2!1spt-BR!2sbr'
          width='600'
          height='450'
          aria-hidden='false'
        ></iframe>
      </Map>

      <Gallery>
        <div id='gallery'>
          <Title>Galeria</Title>
          <div className='photo_grid'>
            <div className='image'>
              <div className='purple'>
                <FaSearchPlus />
              </div>
              <StaticImage
                src='../images/photos/IMG_8941.jpg'
                alt=''
                loading='lazy'
              />
            </div>
            <div className='image'>
              <div className='purple'>
                <FaSearchPlus />
              </div>
              <StaticImage
                src='../images/photos/IMG_8247.jpg'
                alt=''
                loading='lazy'
              />
            </div>
            <div className='image'>
              <div className='purple'>
                <FaSearchPlus />
              </div>
              <StaticImage
                src='../images/photos/IMG_8578.jpg'
                alt=''
                loading='lazy'
              />
            </div>
            <div className='image'>
              <div className='purple'>
                <FaSearchPlus />
              </div>
              <StaticImage
                src='../images/photos/IMG_8750.jpg'
                alt=''
                loading='lazy'
              />
            </div>
            <div className='image'>
              <div className='purple'>
                <FaSearchPlus />
              </div>
              <StaticImage
                src='../images/photos/IMG_8828 2.jpg'
                alt=''
                loading='lazy'
              />
            </div>
            <div className='image'>
              <div className='purple'>
                <FaSearchPlus />
              </div>
              <StaticImage
                src='../images/photos/IMG_8860.jpg'
                alt=''
                loading='lazy'
              />
            </div>
          </div>
        </div>
      </Gallery>

      <Donnate>
        <div id='donate'>
          <Title>E agora?</Title>
          <TitleMinimal>Como posso ajudar?</TitleMinimal>
          <p>
            Você pode se tornar um mantenedor escolhendo o valor que o Senhor
            assim colocar em seu coração, podendo realizar o pagamento, ou
            também a sua doação através das seguintes vias:
          </p>
          <div className='accounts'>
            <div className='accounts-item'>
              <img src={banco} alt='' />
              <p>
                <strong>AG:</strong> 3497-5
              </p>
              <p>
                <strong>CC:</strong> 40769-0
              </p>
            </div>
            <div className='accounts-item'>
              <img src={pix} alt='' />
              <p>
                <strong>Chave:</strong> 10.762.708/0001-33
              </p>
            </div>
            <div className='accounts-item'>
              <h4>Doações de donativos:</h4>
              <p>
                Alimentos, materiais escolares, vestuários, higiene pessoal,
                entre outros.
              </p>
            </div>
          </div>
        </div>
      </Donnate>

      <Responsables>
        <div id='responsables'>
          <Title>Os pastores da missão</Title>
          <div className='responsables-grid'>
            <StaticImage src='../images/valdoir.jpg' alt='' />

            <article>
              <h3>Pr. Valdoir & Pra. Maria Helena</h3>
              <p>
                Desde o ano de 2005 os missionários Valdoir e Maria Helena
                iniciaram idas e vindas do Brasil ao Paraguai. Viram a realidade
                do país, decidiram abrir mão de tudo no Brasil e obedeceram ao
                chamado que há 7 anos queimava em seus corações: cumprir o ide
                na nação vizinha. No ano de 2012, o Ministério Atos de Justiça,
                por direcionamento de Deus, enviou o casal, juntamente con sua
                família para o início dos trabalhos no bairro San Ramon em Pedro
                Ruan Caballero. Próximo a fronteira com o Brasil.
              </p>
            </article>
          </div>
        </div>
      </Responsables>

      <Contact>
        <div id='contact'>
          <Title>Contato</Title>
          <h3 className='subtitle'>
            Nos contate através das seguintes vias de comunicação:
          </h3>
          <div className='contact-grid'>
            <div className='contact-item'>
              <FaMapMarkerAlt />
              <h3>Localização</h3>
              <p>Av. Ministro Jõao Arinos, 4136</p>
              <p>B. Tiradentes | CG-MS</p>
            </div>
            <div className='contact-item'>
              <FaEnvelope />
              <h3>Email</h3>
              <p>contato@atosdejustica.org</p>
            </div>
            <div className='contact-item'>
              <FaPhoneAlt />
              <h3>Telefone</h3>
              <p>(67) 4002-8922</p>
            </div>
          </div>
        </div>
      </Contact>

      <Footer />
    </Container>
  );
};

export default Home;
